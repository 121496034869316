import { defineStore } from 'pinia'
import type { Tooltip } from '~/repository/modules'

export const useTooltipStore = defineStore('tooltip', () => {
  const { $api, $toast } = useNuxtApp()

  const tooltips = ref<Tooltip[]>()
  const loading = ref<boolean>(false)

  const list = async () => {
    loading.value = true
    return $api.tooltips.list()
      .then((response) => {
        tooltips.value = response.data
      }).finally(() => loading.value = false)
  }

  const getTooltipByKey = (key: string) => {
    return tooltips.value?.find(item => item.key === key)
  }

  $api.tooltips.on('created', (_tooltip) => {
    list()
    $toast.addToast({
      title: `${_tooltip.key} Created!`,
    })
  })

  return {
    list,
    tooltips,
    loading,
    getTooltipByKey,
  }
})
